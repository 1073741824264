import React from "react";
import { useTranslation } from "gatsby-plugin-react-i18next";
import { graphql } from "gatsby";
import Layout from "../components/layout";
import Seo from "../components/seo";
import Alert from "@mui/material/Alert";

const HOWTOGETMORE = () => {
  const { t } = useTranslation();
  return (
    <Layout>
      <Seo title={t("aa--headline1")} />
      <h1>{t("aa--headline1")}</h1>
      <div dangerouslySetInnerHTML={{ __html: t("aa--text1") }} />
      <br />
      <Alert severity="info">
        This was written by{" "}
        <a href="https://app.bramework.com" target="_blank" rel="noreferrer">
          Bramework | AI Writing Assistant
        </a>
        .
      </Alert>
      <br />
    </Layout>
  );
};

export default HOWTOGETMORE;

export const query = graphql`
  query ($language: String!) {
    locales: allLocale(filter: { language: { eq: $language } }) {
      edges {
        node {
          ns
          data
          language
        }
      }
    }
  }
`;
